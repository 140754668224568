/** this would wrap all the api endpoints and base urls */
import axios from "axios";
export const botUrl = "https://bot.versuspay.co/";
export const baseUrl = "https://staging-api.reelin.ai/v1";


export const url = {
  samplogin: "login",
  login: "authentication/login",
  register: "signup",
  message: "messages",
  businessReg: "authentication/register/business",
  personalReg: "authentication/register/personal",
  reqOtp: "authentication/verify/send",
  verifyOtp: "authentication/verify/email",
  resetPassword: "authentication/forgot/request",
  vst: "authentication/forgot/validate",
  newPassword: "authentication/forgot/new/password",
  // beneficiariesSelector
  saveBene: "beneficiaries",
  allBanks: "beneficiaries/banks",
  verifyAccount: "beneficiaries/verify",
  searchUser: "beneficiaries/tags/search",
  getToken: "plaid/generate/link-token",
  exchangeToken: "plaid/exchange/public-token",
};


