import { FiSettings } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  PaymentIcon,
  InvoicingIcon,
  ExpenseIcon,
  AnalyticsIcon,
  AccountIcon,
  HomeIcon,
  MainLogo,
} from "../../assets/svgs";
import { useAppDispatch } from "../../app/hooks";
import { logoutResetAll } from "../../features/auth/AuthSlice";

const styles = {
  active:
    "bg-primary/10 text-primary font-medium rounded-lg h-10 px-3 gap-2.5 flex items-center text-sm transition-all duration-200",
  inactive:
    "flex items-center h-10 px-3 gap-2.5 text-sm text-gray-600 hover:bg-gray-50 rounded-lg transition-all duration-200 hover:text-gray-900",
  section:
    "text-xs font-medium text-gray-400 px-3 mb-2 mt-6 uppercase tracking-wider",
};

const Sidebar = ({ current }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const logout = () => {
    dispatch(logoutResetAll());
    window.location.replace("/login");
  };

  return (
    <div className="h-screen flex flex-col border-r border-gray-200 bg-white">
      {/* Logo Section */}
      <div className="flex items-center justify-center h-[70px] border-b border-gray-200">
        <MainLogo />
      </div>

      {/* Main Navigation */}
      <div className="flex-1 flex flex-col px-2 py-4 overflow-y-auto">
        <div className="space-y-1">
          <div
            className={current === 1 ? styles.active : styles.inactive}
            onClick={() => navigate("/dashboard")}
          >
            <HomeIcon />
            <span>Home</span>
          </div>

          <div className={current === 4 ? styles.active : styles.inactive}>
            <ExpenseIcon />
            <span>Documents</span>
          </div>

          <div className={current === 6 ? styles.active : styles.inactive}>
            <AccountIcon />
            <span>Account</span>
          </div>
        </div>

        {/* Settings Section */}
        <div className="mt-auto">
          <p className={styles.section}>Settings</p>
          <div className="space-y-1">
            <div className={current === 6 ? styles.active : styles.inactive}>
              <FiSettings className="text-lg" />
              <span>Preferences</span>
            </div>

            <div className={current === 8 ? styles.active : styles.inactive}>
              <AiOutlineUser className="text-lg" />
              <span>Profile</span>
            </div>

            <div
              className="flex items-center h-10 px-3 gap-2.5 text-sm text-red-600 hover:bg-red-50 rounded-lg transition-all duration-200 hover:text-red-700 cursor-pointer"
              onClick={logout}
            >
              <AiOutlineLogout className="text-lg" />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
