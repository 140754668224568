import React, { useEffect, useState } from 'react'
import DemoNav from './DemoNav';
import Stats from './Stats';
import ExpensesChart from '../Charts/expenses-chart';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { TrendingUp, TrendingDown, ChevronDown, Loader2 } from 'lucide-react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { baseUrl } from '../../utils/endpoints';

interface SalesOverviewData {
  success: boolean;
  data: {
    labels: string[];
    revenue: number[];
    orderCount: number[];
    avgOrderValue: number[];
  };
}

interface CustomerMetricsData {
  success: boolean;
  data: {
    newCustomers: number[];
    growthRate: number;
    totalCustomers: number;
    labels: string[];
  };
}

interface ProductPerformanceData {
  success: boolean;
  data: {
    topProducts: {
      name: string;
      revenue: number;
      unitsSold: number;
      stockLevel: number;
    }[];
    revenueDistribution: {
      product: string;
      percentage: number;
    }[];
  };
}

interface CartAnalyticsData {
  success: boolean;
  data: {
    abandonmentRate: {
      date: string;
      rate: number;
    }[];
    recoveryRate: number;
    potentialRevenue: number;
    timeline: string[];
  };
}

interface TransactionMetricsData {
  success: boolean;
  data: {
    successRate: number;
    failureRate: number;
    paymentMethods: {
      method: string;
      count: number;
      volume: number;
    }[];
    recentFailures: {
      orderId: string;
      amount: number;
      reason: string;
    }[];
  };
}

interface Widget {
  id: string;
  name: string;
  isActive: boolean;
  size: 'medium' | 'large';
  order: number;
}

const Skeleton = ({ className = "" }: { className?: string }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`} />
);

const MetricCardSkeleton = () => (
  <div className="bg-gray-50 p-4 rounded-lg">
    <Skeleton className="h-4 w-24 mb-2" />
    <Skeleton className="h-6 w-32" />
  </div>
);

const ChartSkeleton = () => (
  <div className="animate-pulse space-y-4 w-full h-full flex flex-col items-center justify-center">
    <Loader2 className="w-8 h-8 text-gray-400 animate-spin" />
    <p className="text-sm text-gray-500">Loading chart data...</p>
  </div>
);

const DemoContent = () => {
    const [timeframe, setTimeframe] = useState('monthly');
    const [salesData, setSalesData] = useState<SalesOverviewData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState('current');
    const [customerMetrics, setCustomerMetrics] = useState<CustomerMetricsData | null>(null);
    const [customerTimeframe, setCustomerTimeframe] = useState('weekly');
    const [isCustomerLoading, setIsCustomerLoading] = useState(true);
    const [productData, setProductData] = useState<ProductPerformanceData | null>(null);
    const [sortBy, setSortBy] = useState<'revenue' | 'units' | 'stock'>('revenue');
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [cartData, setCartData] = useState<CartAnalyticsData | null>(null);
    const [cartTimeframe, setCartTimeframe] = useState('daily');
    const [isCartLoading, setIsCartLoading] = useState(true);
    const [widgets, setWidgets] = useState<Widget[]>(() => {
      const savedWidgets = localStorage.getItem('dashboardWidgets');
      if (savedWidgets) {
        return JSON.parse(savedWidgets);
      }
      return [
        { id: 'sales', name: 'Sales Overview', isActive: true, size: 'medium', order: 0 },
        { id: 'customers', name: 'Customer Acquisition', isActive: true, size: 'medium', order: 1 },
        { id: 'products', name: 'Product Performance', isActive: false, size: 'medium', order: 2 },
        { id: 'cart', name: 'Cart Abandonment', isActive: false, size: 'medium', order: 3 },
        { id: 'transactions', name: 'Transaction Status', isActive: false, size: 'medium', order: 4 },
      ];
    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const COLORS = ['#2F80EC', '#6B7280', '#60A5FA', '#93C5FD', '#BFDBFE'];
    const [transactionData, setTransactionData] = useState<TransactionMetricsData | null>(null);
    const [isTransactionLoading, setIsTransactionLoading] = useState(true);

    useEffect(() => {
      localStorage.setItem('dashboardWidgets', JSON.stringify(widgets));
    }, [widgets]);

    useEffect(() => {
      // Clear localStorage only once when testing
      localStorage.removeItem('dashboardWidgets');
    }, []); // Empty dependency array means it runs once

    const getDateRange = () => {
      const now = new Date();
      switch(dateRange) {
        case 'current':
          return {
            startDate: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0],
            endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0]
          };
        case 'last30':
          const thirtyDaysAgo = new Date(now.setDate(now.getDate() - 30));
          return {
            startDate: thirtyDaysAgo.toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0]
          };
        case 'last90':
          const ninetyDaysAgo = new Date(now.setDate(now.getDate() - 90));
          return {
            startDate: ninetyDaysAgo.toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0]
          };
        default:
          return {
            startDate: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0],
            endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0]
          };
      }
    };

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please login again.');
        window.location.replace('/login');
      }
    }, []);

    useEffect(() => {
      const fetchSalesData = async () => {
        setIsLoading(true);
        setError(null);
        try {
          const { startDate, endDate } = getDateRange();
          const token = localStorage.getItem('token');
          
          if (!token) {
            setError('No authentication token found');
            return;
          }

          console.log('Making API call with:', {
            url: `${baseUrl}/v1/dashboard/sales-overview`,
            token: token ? 'Present' : 'Missing',
            params: { timeframe, startDate, endDate }
          });

          const response = await axios.get(`${baseUrl}/v1/dashboard/sales-overview`, {
            params: {
              timeframe: timeframe,
              startDate: startDate,
              endDate: endDate
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          
          console.log('API Response:', response.data);
          setSalesData(response.data);
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
          console.error('API Error:', {
            message: errorMessage,
            status: error.response?.status,
            data: error.response?.data
          });
          setError(errorMessage);
          
          if (error.response?.status === 401) {
            window.location.replace('/login');
          }
        } finally {
          setIsLoading(false);
        }
      };

      fetchSalesData();
    }, [timeframe, dateRange]);

    useEffect(() => {
      console.log('Component mounted or updated with:', {
        timeframe,
        dateRange,
        salesData,
        isLoading,
        baseUrl
      });
    }, [timeframe, dateRange, salesData, isLoading]);

    useEffect(() => {
      console.log('DemoContent component mounted');
      const token = localStorage.getItem('token');
      console.log('Initial token check:', token ? 'Token exists' : 'No token found');
    }, []);

    useEffect(() => {
      const fetchCustomerMetrics = async () => {
        setIsCustomerLoading(true);
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            setError('No authentication token found');
            return;
          }
          const response = await axios.get(`${baseUrl}/v1/dashboard/customer-metrics`, {
            params: {
              timeframe: customerTimeframe
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setCustomerMetrics(response.data);
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
          console.error('Error fetching customer metrics:', error);
          setError(errorMessage);
          if (error.response?.status === 401) {
            window.location.replace('/login');
          }
        } finally {
          setIsCustomerLoading(false);
        }
      };

      fetchCustomerMetrics();
    }, [customerTimeframe]);

    useEffect(() => {
      const fetchProductData = async () => {
        setIsProductLoading(true);
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            setError('No authentication token found');
            return;
          }
          const response = await axios.get(`${baseUrl}/v1/dashboard/product-performance`, {
            params: {
              limit: 10,
              sortBy: sortBy
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setProductData(response.data);
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
          console.error('Error fetching product data:', error);
          setError(errorMessage);
          if (error.response?.status === 401) {
            window.location.replace('/login');
          }
        } finally {
          setIsProductLoading(false);
        }
      };

      fetchProductData();
    }, [sortBy]);

    useEffect(() => {
      const fetchCartAnalytics = async () => {
        setIsCartLoading(true);
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            setError('No authentication token found');
            return;
          }
          const response = await axios.get(`${baseUrl}/v1/dashboard/cart-analytics`, {
            params: {
              timeframe: cartTimeframe
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setCartData(response.data);
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
          console.error('Error fetching cart analytics:', error);
          setError(errorMessage);
          if (error.response?.status === 401) {
            window.location.replace('/login');
          }
        } finally {
          setIsCartLoading(false);
        }
      };

      fetchCartAnalytics();
    }, [cartTimeframe]);

    useEffect(() => {
      const fetchTransactionMetrics = async () => {
        setIsTransactionLoading(true);
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            setError('No authentication token found');
            return;
          }
          const response = await axios.get(`${baseUrl}/v1/dashboard/transaction-metrics`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setTransactionData(response.data);
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
          console.error('Error fetching transaction metrics:', error);
          setError(errorMessage);
          if (error.response?.status === 401) {
            window.location.replace('/login');
          }
        } finally {
          setIsTransactionLoading(false);
        }
      };

      fetchTransactionMetrics();
    }, []);

    const getLatestValue = (arr: number[] | undefined): number | null => {
      if (!arr || arr.length === 0) return null;
      return arr[arr.length - 1];
    };

    const formatValue = (value: number | null, prefix: string = ''): string | null => {
      if (value === null) return null;
      return `${prefix}${value.toLocaleString()}`;
    };

    const renderMetricCard = (label: string, value: string | number | null, isLoading: boolean) => (
      <div className="bg-gray-50 p-4 rounded-lg transition-all duration-200 hover:shadow-md">
        {isLoading ? (
          <MetricCardSkeleton />
        ) : (
          <>
            <p className="text-sm text-gray-600">{label}</p>
            <p className="text-xl font-semibold transition-all duration-200">
              {value !== null && value !== undefined ? value : 'N/A'}
            </p>
          </>
        )}
      </div>
    );

    useEffect(() => {
      console.log('Sales Data:', salesData);
    }, [salesData]);

    const formatChartData = (salesData: SalesOverviewData | null) => {
      if (!salesData?.data) return [];
      
      return salesData.data.labels.map((date, index) => ({
        date,
        revenue: salesData.data.revenue[index],
        orders: salesData.data.orderCount[index],
        avgValue: salesData.data.avgOrderValue[index]
      }));
    };

    const formatCustomerData = (data: CustomerMetricsData | null) => {
      if (!data?.data) return [];
      return data.data.labels.map((label, index) => ({
        period: label,
        customers: data.data.newCustomers[index]
      }));
    };

    const formatCartData = (data: CartAnalyticsData | null) => {
      if (!data?.data) return [];
      return data.data.abandonmentRate.map(item => ({
        date: item.date,
        rate: item.rate
      }));
    };

    const toggleWidget = (widgetId: string) => {
      setWidgets(widgets.map(widget => 
        widget.id === widgetId ? { ...widget, isActive: !widget.isActive } : widget
      ));
    };

    const updateWidgetSize = (widgetId: string, size: 'medium' | 'large') => {
      setWidgets(widgets.map(widget => 
        widget.id === widgetId ? { ...widget, size } : widget
      ));
    };

    const getWidgetClasses = (size: string) => {
      switch(size) {
        case 'medium':
          return 'col-span-2';
        case 'large':
          return 'col-span-4';
        default:
          return 'col-span-2';
      }
    };

    const renderWidget = (widgetId: string, size: 'medium' | 'large') => {
      const getContentClasses = (size: string) => {
        switch(size) {
          case 'medium':
            return {
              gridCols: 'grid-cols-3',
              chartHeight: 'h-[200px]',
              showExtra: true
            };
          case 'large':
            return {
              gridCols: 'grid-cols-4',
              chartHeight: 'h-[250px]',
              showExtra: true
            };
          default:
            return {
              gridCols: 'grid-cols-3',
              chartHeight: 'h-[200px]',
              showExtra: true
            };
        }
      };

      const { gridCols, chartHeight, showExtra } = getContentClasses(size);

      switch(widgetId) {
        case 'sales':
          return (
            <div className="space-y-4">
              <div className={`grid ${gridCols} gap-4`}>
                {renderMetricCard(
                  "Total Revenue",
                  formatValue(salesData?.data ? getLatestValue(salesData.data.revenue) : null, '$'),
                  isLoading
                )}
                {showExtra && (
                  <>
                    {renderMetricCard(
                      "Total Orders",
                      formatValue(salesData?.data ? getLatestValue(salesData.data.orderCount) : null),
                      isLoading
                    )}
                    {renderMetricCard(
                      "Avg Order Value",
                      formatValue(salesData?.data ? getLatestValue(salesData.data.avgOrderValue) : null, '$'),
                      isLoading
                    )}
                  </>
                )}
              </div>
              <div className={`overflow-hidden ${chartHeight}`}>
                {isLoading ? (
                  <ChartSkeleton />
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={formatChartData(salesData)}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
                      <XAxis 
                        dataKey="date" 
                        fontSize={12}
                        tickFormatter={(value) => {
                          const date = new Date(value);
                          return date.toLocaleDateString('en-US', { 
                            month: 'short', 
                            day: 'numeric' 
                          });
                        }}
                        className="transition-all duration-200"
                      />
                      <YAxis 
                        fontSize={12}
                        tickFormatter={(value) => `$${value}`}
                        className="transition-all duration-200"
                      />
                      <Tooltip 
                        formatter={(value: number) => [`$${value}`, 'Revenue']}
                        labelFormatter={(label) => {
                          const date = new Date(label);
                          return date.toLocaleDateString('en-US', { 
                            month: 'long', 
                            day: 'numeric',
                            year: 'numeric'
                          });
                        }}
                        contentStyle={{
                          backgroundColor: 'rgba(255, 255, 255, 0.95)',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="revenue"
                        stroke="#2F80EC"
                        strokeWidth={2}
                        dot={{
                          fill: '#2F80EC',
                          strokeWidth: 2,
                          r: 4,
                          className: 'transition-all duration-200 hover:r-6'
                        }}
                        activeDot={{
                          r: 6,
                          fill: '#2F80EC',
                          stroke: '#fff',
                          strokeWidth: 2,
                          className: 'transition-all duration-200'
                        }}
                        className="transition-all duration-200"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          );
        case 'customers':
          return (
            <div className="bg-white rounded-lg p-5">
              <div className="flex items-center justify-between mb-4">
                <h1 className="font-semibold text-base text-gray-900">Customer Acquisition</h1>
                <select
                  className="px-3 py-1.5 border border-gray-200 rounded-lg text-sm text-gray-600"
                  value={customerTimeframe}
                  onChange={(e) => setCustomerTimeframe(e.target.value)}
                >
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">Total Customers</p>
                  <p className="text-xl font-semibold">
                    {isCustomerLoading ? (
                      <span className="text-gray-400">Loading...</span>
                    ) : (
                      customerMetrics?.data.totalCustomers.toLocaleString()
                    )}
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">Growth Rate</p>
                  <div className="flex items-center gap-2">
                    <p className="text-xl font-semibold">
                      {isCustomerLoading ? (
                        <span className="text-gray-400">Loading...</span>
                      ) : (
                        `${customerMetrics?.data.growthRate}%`
                      )}
                    </p>
                    {customerMetrics?.data.growthRate && customerMetrics.data.growthRate > 0 ? (
                      <TrendingUp className="text-green-500 w-5 h-5" />
                    ) : (
                      <TrendingDown className="text-red-500 w-5 h-5" />
                    )}
                  </div>
                </div>
              </div>

              <div className="overflow-hidden h-[200px] bg-white">
                {isCustomerLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <p className="text-gray-500">Loading chart data...</p>
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={formatCustomerData(customerMetrics)}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="period"
                        fontSize={12}
                        tickFormatter={(value) => {
                          if (customerTimeframe === 'weekly') {
                            return `Week ${value.split('-W')[1]}`;
                          }
                          const date = new Date(value);
                          return date.toLocaleDateString('en-US', {
                            month: 'short',
                            year: '2-digit'
                          });
                        }}
                      />
                      <YAxis
                        fontSize={12}
                      />
                      <Tooltip
                        formatter={(value: number) => [value, 'New Customers']}
                        labelFormatter={(label) => {
                          if (customerTimeframe === 'weekly') {
                            return `Week ${label.split('-W')[1]}`;
                          }
                          const date = new Date(label);
                          return date.toLocaleDateString('en-US', {
                            month: 'long',
                            year: 'numeric'
                          });
                        }}
                      />
                      <Bar
                        dataKey="customers"
                        fill="#2F80EC"
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          );
        case 'products':
          return (
            <div className="bg-white rounded-lg p-5">
              <div className="flex items-center justify-between mb-4">
                <h1 className="font-semibold text-base text-gray-900">Product Performance</h1>
                <select
                  className="px-3 py-1.5 border border-gray-200 rounded-lg text-sm text-gray-600"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value as 'revenue' | 'units' | 'stock')}
                >
                  <option value="revenue">Sort by Revenue</option>
                  <option value="units">Sort by Units</option>
                  <option value="stock">Sort by Stock</option>
                </select>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="h-[200px]">
                  {isProductLoading ? (
                    <div className="flex items-center justify-center h-full">
                      <p className="text-gray-500">Loading chart data...</p>
                    </div>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={productData?.data.revenueDistribution}
                          dataKey="percentage"
                          nameKey="product"
                          cx="50%"
                          cy="50%"
                          outerRadius={70}
                          label={({name, percent}) => `${name} (${(percent * 100).toFixed(1)}%)`}
                        >
                          {productData?.data.revenueDistribution.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value: number) => [`${value.toFixed(1)}%`, 'Revenue Share']}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>

                <div className="h-[200px] overflow-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0">
                      <tr>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Product
                        </th>
                        <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Revenue
                        </th>
                        <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Units
                        </th>
                        <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Stock
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {isProductLoading ? (
                        <tr>
                          <td colSpan={4} className="px-4 py-2 text-center text-sm text-gray-500">
                            Loading...
                          </td>
                        </tr>
                      ) : (
                        productData?.data.topProducts.map((product, index) => (
                          <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                              {product.name}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-right text-gray-900">
                              ${product.revenue.toLocaleString()}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-right text-gray-900">
                              {product.unitsSold.toLocaleString()}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-right text-gray-900">
                              {product.stockLevel.toLocaleString()}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        case 'cart':
          return (
            <div className="bg-white rounded-lg p-5">
              <div className="flex items-center justify-between mb-4">
                <h1 className="font-semibold text-base text-gray-900">Cart Abandonment</h1>
                <select
                  className="px-3 py-1.5 border border-gray-200 rounded-lg text-sm text-gray-600"
                  value={cartTimeframe}
                  onChange={(e) => setCartTimeframe(e.target.value)}
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>

              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">Abandonment Rate</p>
                  <p className="text-xl font-semibold">
                    {isCartLoading ? (
                      <span className="text-gray-400">Loading...</span>
                    ) : (
                      `${cartData?.data.abandonmentRate[cartData.data.abandonmentRate.length - 1]?.rate.toFixed(1)}%`
                    )}
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">Recovery Rate</p>
                  <p className="text-xl font-semibold">
                    {isCartLoading ? (
                      <span className="text-gray-400">Loading...</span>
                    ) : (
                      `${cartData?.data.recoveryRate.toFixed(1)}%`
                    )}
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">Potential Revenue Loss</p>
                  <p className="text-xl font-semibold">
                    {isCartLoading ? (
                      <span className="text-gray-400">Loading...</span>
                    ) : (
                      `$${cartData?.data.potentialRevenue.toLocaleString()}`
                    )}
                  </p>
                </div>
              </div>

              <div className="overflow-hidden h-[200px] bg-white">
                {isCartLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <p className="text-gray-500">Loading chart data...</p>
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={formatCartData(cartData)}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        fontSize={12}
                        tickFormatter={(value) => {
                          const date = new Date(value);
                          return date.toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric'
                          });
                        }}
                      />
                      <YAxis
                        fontSize={12}
                        tickFormatter={(value) => `${value}%`}
                      />
                      <Tooltip
                        formatter={(value: number) => [`${value.toFixed(1)}%`, 'Abandonment Rate']}
                        labelFormatter={(label) => {
                          const date = new Date(label);
                          return date.toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          });
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="rate"
                        stroke="#EF4444"
                        strokeWidth={2}
                        dot={{
                          fill: '#EF4444',
                          strokeWidth: 2,
                          r: 4,
                        }}
                        activeDot={{
                          r: 6,
                          fill: '#EF4444',
                          stroke: '#fff',
                          strokeWidth: 2,
                        }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          );
        case 'transactions':
          return (
            <div className="bg-white rounded-lg p-5">
              <div className="flex items-center justify-between mb-4">
                <h1 className="font-semibold text-base text-gray-900">
                  Transaction Status
                </h1>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-4">
                  <div className="h-[160px]">
                    {isTransactionLoading ? (
                      <div className="flex items-center justify-center h-full">
                        <p className="text-gray-500">Loading chart data...</p>
                      </div>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={[
                              { name: 'Success', value: transactionData?.data.successRate || 0 },
                              { name: 'Failure', value: transactionData?.data.failureRate || 0 }
                            ]}
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={70}
                            dataKey="value"
                          >
                            <Cell fill="#22C55E" />
                            <Cell fill="#EF4444" />
                          </Pie>
                          <Tooltip 
                            formatter={(value: number) => [`${value.toFixed(1)}%`, 'Rate']}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                  <div className="text-center">
                    <p className="text-sm text-gray-600">Success Rate</p>
                    <p className="text-3xl font-semibold text-green-600">
                      {isTransactionLoading ? (
                        <span className="text-gray-400">Loading...</span>
                      ) : (
                        `${transactionData?.data.successRate.toFixed(1)}%`
                      )}
                    </p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <h2 className="text-sm font-medium text-gray-700 mb-2">Payment Methods</h2>
                    <div className="space-y-2 max-h-[100px] overflow-y-auto">
                      {isTransactionLoading ? (
                        <p className="text-gray-500 text-sm">Loading...</p>
                      ) : (
                        transactionData?.data.paymentMethods.map((method, index) => (
                          <div key={index} className="flex justify-between text-sm">
                            <span className="text-gray-600">{method.method}</span>
                            <span className="text-gray-900">${method.volume.toLocaleString()}</span>
                          </div>
                        ))
                      )}
                    </div>
                  </div>

                  <div>
                    <h2 className="text-sm font-medium text-gray-700 mb-2">Recent Failures</h2>
                    <div className="space-y-2 max-h-[100px] overflow-y-auto">
                      {isTransactionLoading ? (
                        <p className="text-gray-500 text-sm">Loading...</p>
                      ) : (
                        transactionData?.data.recentFailures.map((failure, index) => (
                          <div key={index} className="text-sm space-y-1">
                            <div className="flex justify-between">
                              <span className="text-gray-600">{failure.orderId}</span>
                              <span className="text-gray-900">${failure.amount.toLocaleString()}</span>
                            </div>
                            <p className="text-red-500 text-xs">{failure.reason}</p>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

    const handleDragEnd = (result: any) => {
      if (!result.destination) return;
      
      const items = Array.from(widgets);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      
      const updatedItems = items.map((item, index) => ({
        ...item,
        order: index
      }));
      
      setWidgets(updatedItems);
    };

    const SizeControl = ({ widgetId, currentSize }: { widgetId: string, currentSize: string }) => (
      <select
        className="px-3 py-1.5 border border-gray-200 rounded-lg text-sm text-gray-600
                   transition-all duration-200 hover:border-blue-400 focus:ring-2 
                   focus:ring-blue-100 focus:border-blue-400 outline-none"
        value={currentSize}
        onChange={(e) => updateWidgetSize(widgetId, e.target.value as 'medium' | 'large')}
      >
        <option value="medium">Medium</option>
        <option value="large">Large</option>
      </select>
    );

    if (error) {
      return (
        <div className="w-full min-h-screen bg-gray-50 flex items-center justify-center">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">Error Loading Dashboard</h2>
            <p className="text-gray-600">{error}</p>
            {error.includes('token') && (
              <button
                onClick={() => window.location.replace('/login')}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Go to Login
              </button>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="w-full min-h-screen">
        <div className="h-[60px] bg-white border-b border-gray-200">
          <DemoNav />
        </div>
        
        <div className="bg-[#F7F7F7] min-h-[calc(100vh-60px)]">
          <div className="max-w-[1600px] mx-auto p-6">
            <div className="flex justify-end mb-6">
              <div className="relative">
                <button
                  className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg border border-gray-200 text-sm text-gray-600 hover:bg-gray-50"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Manage Widgets
                  <ChevronDown className="w-4 h-4" />
                </button>
                
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 z-10">
                    <div className="p-2">
                      {widgets.map(widget => (
                        <div key={widget.id} className="flex items-center justify-between px-3 py-2 hover:bg-gray-50 rounded">
                          <label className="flex items-center gap-2 cursor-pointer">
                            <input
                              type="checkbox"
                              checked={widget.isActive}
                              onChange={() => toggleWidget(widget.id)}
                              className="rounded border-gray-300 text-blue-600"
                            />
                            <span className="text-sm text-gray-700">{widget.name}</span>
                          </label>
                          {widget.isActive && (
                            <SizeControl widgetId={widget.id} currentSize={widget.size} />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="widgets" direction="horizontal">
                {(provided) => (
                  <div 
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="grid grid-cols-4 gap-6"
                  >
                    {widgets
                      .filter(widget => widget.isActive)
                      .sort((a, b) => a.order - b.order)
                      .map((widget, index) => (
                        <Draggable 
                          key={widget.id} 
                          draggableId={widget.id} 
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`
                                ${getWidgetClasses(widget.size)}
                                transition-all duration-300 ease-in-out
                                ${snapshot.isDragging ? 'scale-105 shadow-xl' : ''}
                              `}
                            >
                              <div 
                                {...provided.dragHandleProps}
                                className="bg-white rounded-lg p-5 transition-all duration-200 hover:shadow-lg"
                              >
                                <div className="flex items-center justify-between mb-4">
                                  <h1 className="font-semibold text-base text-gray-900">
                                    {widget.name}
                                  </h1>
                                  <div className="flex items-center gap-2">
                                    <SizeControl widgetId={widget.id} currentSize={widget.size} />
                                  </div>
                                </div>
                                {renderWidget(widget.id, widget.size)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
}

export default DemoContent
