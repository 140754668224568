import HelpSession from "../components/Convert/HelpSession";
import Sidebar from "../components/Sidebar/Sidebar";

const DashboardLayout = ({ children, current } :any) => {
  return (
    <div className="w-full flex flex-col bg-white">
      <div className="flex flex-col lg:hidden mt-6 px-4 justify-center h-screen">
        <h1 className="font-bold text-customBlack text-base text-center mb-2 ">
          Sorry, this page is not optimized for mobile devices.
        </h1>
        <p className="text-neutral-700 text-sm  ">
          Please try viewing it on a larger screen, such as a laptop or desktop
          computer, for a better experience.
        </p>
        <p className="text-neutral-700 text-sm  ">
          Thank you for your understanding. 😊
        </p>
      </div>
      <div className="w-full h-screen bg-[#fff] relative md:flex hidden">
        <div className="w-[180px] min-w-[180px] h-screen">
          <Sidebar current={current} />
        </div>

        <div className="flex-1 flex flex-col h-full flow-hide px-8 py-6">
          <div className="w-full min-h-screen flow-hide">{children}</div>
          {/* <HelpSession /> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
