import { useEffect, useState, useRef } from "react";
import { SearchIcon, Notification, SendIcon, WhiteCancel } from "../../assets/svgs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetQues, sendMessage } from "../../features/auth/AuthSlice";
import NavDrop from "../Customs/NavDrop";
import ReUseModal from "../Modal/ReUseModal";
import CsvToPlainTextConverter from "../Convert/FileConvert";
import SideOverlay from "../Sideoverlay/Sideoverlay";
import siri from "../../assets/png/siri.png";
import baby from "../../assets/png/64px.png";
import loading from "../../assets/png/loading-animation.gif";
import sampA from "../../assets/png/upload-pic.png";
import { Btn } from "../Button";

export interface singleMessageProps {
  _id?: string;
  user?: string;
  body?: string;
  fromChat?: boolean;
  type?: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
}

const DemoNav = () => {
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [question, setQuestion] = useState("");
  const [dataQues, setDataQues] = useState("");
  const [sentQues, setSentQues] = useState("");
  const [answer, setAnswer] = useState("");
  const [resp, setResp] = useState<any>({});
  const bottomRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const handlePost = () => {
    dispatch(resetQues());
    setAnswer("");
    const data = {
      body: dataQues,
      type: "csv",
    };
    dispatch(sendMessage(data));
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handlePost();
    }
  };

  return (
    <div className="h-full px-6">
      <div className="h-full flex items-center justify-between">
        {/* Left Section */}
        <div className="flex items-center gap-4">
          <div className="relative">
            <div className="flex items-center gap-3 bg-[#F7F7F7] rounded-lg px-4 py-2 w-[480px] hover:bg-gray-100 transition-colors duration-200">
              <span className="text-gray-400">
                <SearchIcon />
              </span>
              <input
                type="text"
                placeholder="Search transactions, reports..."
                className="bg-transparent outline-none text-sm w-full text-gray-700 placeholder-gray-400"
              />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex items-center gap-4">
          <button 
            onClick={() => setOpen(true)}
            className="flex items-center gap-2 px-4 py-2 bg-[#2F80EC] text-white rounded-lg hover:bg-[#2F80EC]/90 transition-all duration-200"
          >
            <span className="text-sm font-medium">Upload CSV</span>
          </button>

          <button 
            onClick={() => setOpenSidebar(true)}
            className="relative p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
          >
            <span className="text-gray-600">
              <Notification />
            </span>
            <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-red-500 rounded-full"></span>
          </button>

          <NavDrop />
        </div>
      </div>

      {/* Upload Modal */}
      <ReUseModal open={open} setOpen={setOpen}>
        <div className="w-full max-w-lg mx-auto p-8">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h2 className="text-2xl font-semibold text-gray-800">Upload CSV File</h2>
              <p className="text-sm text-gray-500 mt-1">Import your transaction data</p>
            </div>
            <button 
              onClick={() => setOpen(false)} 
              className="text-gray-400 hover:text-gray-600 transition-colors p-2 hover:bg-gray-100 rounded-lg"
            >
              <WhiteCancel />
            </button>
          </div>
          
          <div className="space-y-6">
            <div className="border-2 border-dashed border-gray-200 rounded-xl p-8 hover:border-primary/50 transition-colors bg-gray-50">
              <div className="flex items-center gap-6">
                <img src={sampA} alt="Upload" className="w-16 h-16" />
                <div>
                  <CsvToPlainTextConverter setDataQues={setDataQues} />
                  <p className="text-sm text-gray-500 mt-2">
                    Drag and drop your CSV file here, or click to browse
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    Supported formats: CSV
                  </p>
                </div>
              </div>
            </div>

            <Btn
              name={auth?.loading ? "Processing..." : "Upload and Process"}
              active={true}
              onClick={handlePost}
            />
          </div>
        </div>
      </ReUseModal>

      {/* Sidebar Overlay */}
      <SideOverlay open={openSidebar} setOpen={setOpenSidebar}>
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="bg-white px-6 py-5 flex items-center justify-between border-b border-gray-200">
            <div className="flex items-center gap-3">
              <img src={siri} alt="Assistant" className="w-9 h-9" />
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Reelin Assistant</h2>
                <p className="text-sm text-gray-500">AI-powered support</p>
              </div>
            </div>
            <button 
              onClick={() => setOpenSidebar(false)} 
              className="text-gray-400 hover:text-gray-600 p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <WhiteCancel />
            </button>
          </div>

          {/* Chat Area */}
          <div className="flex-1 overflow-y-auto px-6 py-6 space-y-4">
            {sentQues && (
              <div className="flex gap-3">
                <img src={baby} alt="User" className="w-8 h-8 rounded-full flex-shrink-0" />
                <div className="flex-1 bg-gray-100 rounded-xl p-3">
                  <p className="text-gray-800 text-sm">{sentQues}</p>
                  <span className="text-xs text-gray-500 mt-1.5 block">
                    {new Date().toLocaleString()}
                  </span>
                </div>
              </div>
            )}

            {answer && (
              <div className="flex gap-3">
                <img src={resp.fromChat ? siri : baby} alt="Assistant" className="w-8 h-8 rounded-full flex-shrink-0" />
                <div className={`flex-1 rounded-xl p-3 ${resp.fromChat ? "bg-blue-50" : "bg-gray-100"}`}>
                  <p className="text-gray-800 text-sm">{answer}</p>
                  <span className="text-xs text-gray-500 mt-1.5 block">
                    {new Date().toLocaleString()}
                  </span>
                </div>
              </div>
            )}

            {auth?.loading && (
              <div className="flex justify-center py-2">
                <img src={loading} alt="Loading" className="w-6 h-6" />
              </div>
            )}
          </div>

          {/* Input Area */}
          <div className="border-t border-gray-200 px-6 py-4 bg-white">
            <div className="flex items-center gap-3 bg-gray-50 rounded-xl px-4 py-3">
              <input
                type="text"
                placeholder="Ask anything about your finances..."
                className="flex-1 bg-transparent outline-none text-sm text-gray-700 placeholder-gray-400"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <button 
                onClick={handlePost}
                className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                disabled={auth?.loading}
              >
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
      </SideOverlay>
    </div>
  );
};

export default DemoNav;
